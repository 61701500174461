import { Component } from '@angular/core';
import { IModalHeaderConfig } from '../components/modal-header/modal-header.component';
import { ModalController } from '@ionic/angular';
import {TranslateService} from "@ngx-translate/core";
import {EventLoggerService} from "../../../providers/event-logger/event-logger-service";

@Component({
  selector: 'app-scan-compatible-modal',
  templateUrl: './scan-compatible-modal.component.html',
  styleUrls: ['./scan-compatible-modal.component.scss']
})
export class ScanCompatibleModalComponent {
  headerConfig: IModalHeaderConfig = {
    logo: './assets/imgs/wallet-connect.png',
    title: 'Scan compatible',
    subtitle: {
      label: 'WalletConnect.compatible.label',
      verified:true
    }
  }

  constructor (
      public modalController: ModalController,
      public translateService: TranslateService,
      public eventLogger:EventLoggerService
  ) {
    this.eventLogger.logScreen('walletConnectExplanationModal', {});

    this.headerConfig.title = this.translateService.instant('WalletConnect.compatible.scanCompatible')
  }
}
