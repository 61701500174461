import { Component } from '@angular/core';
import { NETWORK } from '@arianee/arianeejs/dist/src';
import { UserService } from '../../providers/user-service/user.service';
import { Subscription } from 'rxjs';
import { Platform } from '@ionic/angular';
import { FeatureFlipService } from '../../providers/feature-flip-service/feature-flip.service';
import { mergeMap, tap } from 'rxjs/operators';

@Component({
  selector: 'network-watemark',
  templateUrl: './network-watemark.component.html',
  styleUrls: ['./network-watermark.component.scss']
})
export class NetworkWatemarkComponent {
    private subscriptions:Array<Subscription>=[];

    public isTestNetMode=false;
    public network:NETWORK;

    constructor (private userService:UserService,
                 private platform:Platform,
                 private featureFlipService:FeatureFlipService
    ) {

    }

    ngOnInit () {
      this.platform.ready().then(() => {
        const sub =
            this.featureFlipService.$isFeatureFlip('hideNetworkBanner')
              .pipe(
                mergeMap(isFeatureFlipped => {
                  return this.userService
                    .chain
                    .get()
                    .pipe(tap(network => {
                      if (isFeatureFlipped) {
                        this.isTestNetMode = false;
                      } else {
                        const testnets = [NETWORK.testnet, NETWORK.mumbai];
                        this.isTestNetMode = testnets.includes(network);
                      }
                    }));
                })
              )
              .subscribe(network => {
                this.network = network;
              });
        this.subscriptions.push(sub);
      });
    }

    ngOnDestroy () {
      this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
