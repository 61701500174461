import { Component, Input } from '@angular/core';

export interface IModalList {
  icon: string;
  label: string;
}

@Component({
  selector: 'app-modal-list-with-icon',
  templateUrl: './modal-list-with-icon.component.html',
  styleUrls: ['./modal-list-with-icon.component.scss']
})
export class ModalListWithIconComponent {
  @Input() data: IModalList[] = [];
}
