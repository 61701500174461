import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';

@Injectable({
  providedIn: 'root'
})
export class BiometricProxyService {
  constructor (
              private platform: Platform,
              private originalService: FingerprintAIO
  ) {
  }

  private shouldBeMock () {
    return this.platform.is('desktop') || this.platform.is('mobileweb');
  }

  public get fingerPrintService () {
    if (!this.shouldBeMock()) {
      return this.originalService;
    } else {
      console.warn('biometric provider is mocked for browser');
      return {
        show (): Promise<any> {
          return Promise.reject(new Error('Biometric is not available in the mock'));
        },
        isAvailable (): Promise<string> {
          return Promise.resolve('finger');
        }
      };
    }
  }
}
