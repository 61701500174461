import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ArianeeService } from '../../providers/arianee-service/arianee.service';
import { CertificateSummary } from '@arianee/arianeejs/dist/src/core/wallet/certificateSummary';
import { take } from 'rxjs/operators';
import { LoaderService } from '@arianeeprivate/wallet-shared-components';

@Component({
  templateUrl: './delete-passport.component.html',
  styleUrls: ['./delete-passport.component.scss']
})
export class DeletePassportComponent {
  public certificateId: string;
  public certificate:CertificateSummary;
  constructor (
      private modalCtrl: ModalController,
      private loaderService:LoaderService,
      private arianeeService:ArianeeService
  ) {
  }

  async onCancel () {
    await this.modalCtrl.dismiss();
  }

  async onConfirm () {
    this.loaderService.showLoaderWithBlockchain();

    try {
      const methods = await this.arianeeService.methodsOnce
        .toPromise();
      await methods.destroyCertificate(this.certificateId)
        .pipe(take(1))
        .toPromise();
      this.loaderService.dismiss();
    } catch (e) {
      this.loaderService.dismiss();
    }

    await this.modalCtrl.dismiss();
  }
}
