import { Injectable } from '@angular/core';
import { UserService } from '../user-service/user.service';
import { map, mergeMap, tap } from 'rxjs/operators';
import { combineLatest, from, Observable, ObservableInput, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { ArianeeService } from '../arianee-service/arianee.service';
import CryptoJS from 'crypto-js';
import { Platform } from '@ionic/angular';

var pjson = require('../../../../package.json');

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private getVersionNumber;
  private getVersionCode;

  constructor (
    public userService: UserService,
    private appVersion: AppVersion,
    private arianeeJSService:ArianeeService,
    private platform:Platform) {
  }

  public getProfile = () => {
    if (!this.getVersionNumber) {
      if (this.platform.is('cordova')) {
        this.getVersionNumber = this.appVersion.getVersionNumber();
        this.getVersionCode = this.appVersion.getVersionCode();
      } else {
        this.getVersionNumber = Promise.resolve(pjson.version);
        this.getVersionCode = Promise.resolve(pjson.version);
      }
    }
    return combineLatest([
      this.userService.hasPinCode,
      this.userService.hasOnboarded(),
      this.userService.$backup.get()
        .pipe(map(items => items.filter(item => (item.type === 'google' || item.type === 'icloud')).length > 0)),
      this.userService.$backup.get()
        .pipe(map(items => items.filter(item => item.type === 'manual').length > 0)),
      this.userService.$devToolStorage.get(),
      from(this.getVersionNumber),
      from(this.getVersionCode),
      this.arianeeJSService.$publicKey
        .pipe(map(pubKey => CryptoJS.SHA256(pubKey).toString())),
      this.userService.$numberOfAppLaunch.get(),
      this.userService
        .chain
        .get(),
      this.getNumberOfCertificate(),
      this.getNumberOfMessages()
    ]
    ).pipe(map(values => {
      const [
        pincode,
        onboarded,
        hasGoogleBackup,
        hasManualBackup,
        hasDevTool,
        buildNb,
        versionCode,
        publicKey,
        numberOfAppLaunch,
        network,
        numberOfCertificates,
        numberOfMessages
      ] = values;

      return {
        baidu: environment.isBaidu,
        appType: environment.appType,
        network,
        name: environment.name,
        production: environment.production,
        environment: environment.environment,
        hasPinCode: pincode,
        hasOnboarded: JSON.parse(onboarded),
        hasDevTool: JSON.parse(hasDevTool),
        backupGoogle: hasGoogleBackup,
        backupManual: hasManualBackup,
        buildNb,
        versionCode,
        numberOfAppLaunch,
        publicKey,
        numberOfMessages,
        numberOfCertificates
      };
    }));
  }

  private getNumberOfCertificate = (): Observable<number> => {
    if (environment.appType === 'web') {
      return of(0);
    }
    return this.arianeeJSService.$wallet.pipe(
      mergeMap(wallet => wallet.methods.getCertificatesId()),
      map(certificates => certificates ? certificates.length : 0)
    );
  };

  private getNumberOfMessages = (): Observable<number> => {
    if (environment.appType === 'web') {
      return of(0);
    }
    return this.arianeeJSService.$wallet.pipe(
      mergeMap(wallet => wallet.methods.getAllMyMessageIds() as unknown as ObservableInput<any[]>),
      map((wallet) => wallet.length)
    );
  };
}
