export class MockBrowserNativeStorage {
    setItem = (reference: string, value: any): Promise<any> => {
      localStorage.setItem(reference, JSON.stringify(value));
      return Promise.resolve(value);
    }

    clear=() => {
      localStorage.clear();
      return Promise.resolve();
    }

    getItem = (reference: string): Promise<any> => {
      if (localStorage.getItem(reference)) {
        return Promise.resolve(JSON.parse(localStorage.getItem(reference)));
      }
      return Promise.reject(reference);
    }
}
