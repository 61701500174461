import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class WhatPlatformService {
  constructor (private platform: Platform) {

  }

  public isIOS = () => {
    return this.platform.is('ios');
  }

    public isPWA = () => {
      return environment.appType === 'web';
    }

  public shouldBeMock = () => {
    return this.platform.is('desktop') || this.platform.is('mobileweb');
  }
}
