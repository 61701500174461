import { Injectable } from '@angular/core';
import { ArianeeService } from '../../arianee-service/arianee.service';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { NavController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { LoaderService, ToasterService, toastMessageType } from '@arianeeprivate/wallet-shared-components';
import { decryptLink } from './helpers/decryptLink';
import { DeepLinkObject } from '../../../models/deepLinkObject';
import { isLinkValid } from './helpers/isLinkValid';
import { HandleCertificateLinkService } from './handle-certificate-link.service';
import { HandleInappLinkService } from './handle-inapp-link.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';
import {WalletConnectService} from "../../wallet-connect-service/wallet-connect.service";
import {getWcLinkFromDeeplink} from "./helpers/getWcLink";

@Injectable({
  providedIn: 'root'
})
export class HandleLinkService {
  constructor (private arianeeService: ArianeeService,
                private toasterService: ToasterService,
                private loaderService: LoaderService,
                private navCtrl: NavController,
                private httpClient: HttpClient,
                private handleCertificateLinkService: HandleCertificateLinkService,
                private handleInAppLinkService: HandleInappLinkService,
                private translateService: TranslateService,
               private walletConnectService:WalletConnectService
  ) {

  }

    private isWalletConnectLink(link: string): any {
      const regexResult = getWcLinkFromDeeplink(link);
      return regexResult !== null;
    }



    public handleLink = (link: string): Observable<any> => {
      this.loaderService.showLoader();
      link = decryptLink(link);

      const isNativeLinkHandled = this.handleNativeLink(link);
      if (isNativeLinkHandled) {
        return of(isNativeLinkHandled);
      } else if (this.handleInAppLinkService.isInAppLink(link)) {
        return of(link)
          .pipe(tap(d =>
            this.redirectWithFirstNavigation(this.handleInAppLinkService.extractInAppLink(link))));
      } else if(this.isWalletConnectLink(link)){
        const wcLink = getWcLinkFromDeeplink(link);
        this.walletConnectService.handleLink(wcLink);
        return of(false);
      }
      else {
        console.info('handleLink ## not a a native link');

        return this.handleCertificateLinkService.transformAnyLinkIntoDeepLinkObject(link)
          .pipe(
            map(linkObject => {
              const isLink = isLinkValid(linkObject);
              if (!isLink) {
                this.toasterService.toastMessage(toastMessageType.notReadable);
                throw new Error(toastMessageType.notReadable);
              }
              return linkObject;
            }),
            mergeMap(linkObject => {
              return this.handleCertificateLinkService.tryReadLink(linkObject.deeplink);
            }),
            mergeMap((linkObject) => {
              if (linkObject) {
                return this.getCertificateAndVerifyValidity(linkObject);
              }
            }),
            catchError((e) => {
              const errorMessageTranslated = this.translateService.instant(e.message) || toastMessageType.notReadable;
              this.loaderService.dismiss('productDetail');
              this.toasterService.toastMessage(errorMessageTranslated as any);
              throw new Error(toastMessageType.notReadable);
            })
          );
      }
    }

    public getCertificateAndVerifyValidity (linkObject: DeepLinkObject): Observable<DeepLinkObject> {
      return this.arianeeService.methodsOnce
        .pipe(
          mergeMap(methods => methods.getCertificate(linkObject.certificateId, linkObject.passphrase, {
            content: true,
            owner: true,
            issuer: true
          })),
          mergeMap(certificate => {
            const isCertificateExist = certificate.owner !== undefined;

            const isCertificateReachable = certificate.content !== undefined;

            const authorizedAddress: string[] = environment.authorizedIssuers;
            const issuerAddress = certificate.issuer.identity.address;
            if (authorizedAddress && authorizedAddress.length > 0) {
              const isIncluded = authorizedAddress
                .map(d => d.toLowerCase())
                .includes(issuerAddress.toLowerCase());
              // the issuer is not authorized by this app
              if (!isIncluded) {
                throw new Error('Scan.notReadableInThisApp');
              }
            }

            const externalContents: undefined | { type: string, url: string }[] = get(certificate, 'content.data.externalContents');

            if (externalContents) {
              const authRedirectTo = externalContents.find(d => d.type === 'authRedirectTo');
              if (authRedirectTo) {
                return this.handleLink(authRedirectTo.url);
              }
            }

            if (!isCertificateExist) {
              this.loaderService.dismiss();
              throw new Error(toastMessageType.notExist);
            } else if (!isCertificateReachable) {
              this.loaderService.dismiss();
              throw new Error(toastMessageType.notAuthorized);
            }
            this.loaderService.dismiss();
            return of(linkObject);
          })
        );
    }

    /**
     * Create a first (hidden) navigation to tab/brand-list to allow back navigation
     * @param url
     */
    public async redirectWithFirstNavigation (url: string) {
      console.log('url', url);
      window.history.pushState({ page_id: 1 }, '', '/tab/brand-list/');
      this.navCtrl.navigateForward(url);
    }

    /**
     * Handle native link shaped like: com.arianee.wallet:// for notification
     * @param link
     */
    public handleNativeLink (link) {
      if (link.startsWith('com.arianee.wallet://')) {
        const [method, certificateId] = link.substring(21).split('/');

        if (method === 'item') {
          const linkObject = {
            certificateId: certificateId,
            method: 'notification'
          };
          return linkObject;
        }
      }
    }
}
