import { ArianeeService } from '../arianee-service/arianee.service';
import { UserService } from '../user-service/user.service';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map, mergeMap, take, tap } from 'rxjs/operators';
import { GoogleDriveService } from '../google-drive-service/google-drive.service';
import { AlertController, Platform } from '@ionic/angular';
import { CloudSettingsService } from '../keychain-service/cloud-settings.service';
import { backupType, backupTypeEnum, LoaderService } from '@arianeeprivate/wallet-shared-components';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class BackupService {
  constructor (
    private arianeeService: ArianeeService,
    private userService: UserService,
    private googleDriveService: GoogleDriveService,
    private cloudSettingsService: CloudSettingsService,
    private platform: Platform,
    private alertController:AlertController,
    private translateService:TranslateService,
    private loaderService:LoaderService

  ) {

  }

  init () {
    this.getBackupCurrentWallet()
      .pipe(
        take(1)
      )
      .subscribe();

    this.arianeeService.$wallet
      .pipe(
        tap(() => this.getBackupCurrentWallet())
      )
      .subscribe();
  }

  public $backupCurrentWalletStatus = new ReplaySubject<any>(1);

  public hasBackup (): Observable<boolean> {
    return this.$backupCurrentWalletStatus
      .pipe(
        map(items => items.length > 0)
      );
  }

  private hasCertificateAndNotBackup = async (): Promise<boolean> => {
    const [wallet, hasBackup] = await Promise.all([
      this.arianeeService.$wallet.pipe(take(1)).toPromise(),
      this.hasBackup().pipe(take(1)).toPromise()
    ]);
    const certificates = await wallet.methods.getMyCertificates({ content: true });

    return certificates.length > 0 && hasBackup === false;
  };

  /**
   * Display confirm popup if not safe and return true of false if import should continue
   */
  public alertIfImportBackupNotSafe=async ():Promise<boolean> => {
    this.loaderService.showLoader();
    const shouldDisplayAlert = await this.hasCertificateAndNotBackup();
    if (shouldDisplayAlert) {
      const alert = await this.alertController.create({
        cssClass: 'e2e-backup_hazard_modal',
        header: this.translateService.instant('Import.hazardModal.title'),
        message: this.translateService.instant('Import.hazardModal.description'),
        buttons: [{
          text: this.translateService.instant('Import.hazardModal.buttons.cancel'),
          role: 'cancel',
          cssClass: 'e2e-button_cancel'
        }, {
          text: this.translateService.instant('Import.hazardModal.buttons.ok'),
          role: 'confirm',
          cssClass: 'e2e-button_ok'
        }]
      });
      this.loaderService.dismiss();

      await alert.present();

      const { role } = await alert.onDidDismiss();

      return role === 'confirm';
    } else {
      this.loaderService.dismiss();
      return true;
    }
  }

  public setBackup (date: number, isBackedUp: boolean, type:backupType, email?: string):Observable<any> {
    return this.arianeeService.$publicKey
      .pipe(
        mergeMap((address) => {
          return this.userService.setBackup(date, address, isBackedUp, type, email)
            .pipe(map(backups => {
              return { backups: backups, address: address };
            }));
        }),
        tap(({ backups, address }) => {
          const currentWalletBackups = backups.filter(item => item.address === address);
          this.$backupCurrentWalletStatus.next(currentWalletBackups);
        })
      );
  }

  private getBackupCurrentWallet ():Observable<any> {
    return this.userService.getBackups()
      .pipe(
        mergeMap((backups) => {
          return this.arianeeService.$publicKey.pipe(
            map((address) => {
              return backups.filter(item => item.address === address);
            }),
            tap((backups) => { this.$backupCurrentWalletStatus.next(backups); })
          );
        })
      );
  }

  public backupMnemonic () {
    const isIos = this.platform.is('ios');
    if (isIos) {
      return this.cloudSettingsService.backupMnemonic();
    } else {
      return this.googleDriveService.backupMnemonic();
    }
  }

  public logout=async ():Promise<any> => {
    const isIos = this.platform.is('ios');
    if (!isIos) {
      return this.googleDriveService.logout();
    }
  }

  public async checkBackup () {
    const isIos = this.platform.is('ios');
    if (isIos) {
      const actualWalletBackup = await this.cloudSettingsService.getActualWalletBackup();
      return this.setBackup(Date.now(), !!actualWalletBackup, backupTypeEnum.icloud).pipe(take(1)).toPromise();
    } else {
      const { files, email } = await this.googleDriveService.checkBackup();
      return this.setBackup(Date.now(), !!files[0], backupTypeEnum.google, email).pipe(take(1)).toPromise();
    }
  }

  public getBackupsId () {
    const isIos = this.platform.is('ios');
    if (isIos) {
      return this.cloudSettingsService.getBackupsId();
    } else {
      return this.googleDriveService.getBackups();
    }
  }

  public getMnemonic =async (backupId):Promise<string> => {
    const isIos = this.platform.is('ios');
    let encryptedMnemonic;
    if (isIos) {
      encryptedMnemonic = await this.cloudSettingsService.getMnemonic(backupId);
    } else {
      encryptedMnemonic = await this.googleDriveService.getFileContent(backupId);
    }

    if (encryptedMnemonic && encryptedMnemonic.mnemonic) {
      return this.userService.decryptMnemonic(encryptedMnemonic.mnemonic);
    }
  }

  public clearBackup () {
    const isIos = this.platform.is('ios');
    if (isIos) {
      return this.cloudSettingsService.clearBackup();
    } else {
      // return this.googleDriveService.getBackups();
    }
  }
}
