import { Component, Input, OnInit } from '@angular/core';
import { IModalHeaderConfig } from '../components/modal-header/modal-header.component';
import { ModalController } from '@ionic/angular';
import { getChainName } from '../../../providers/wallet-connect-service/chain-utils';
import { get } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-wallet-switch-chain-modal',
  templateUrl: './wallet-switch-chain-modal.component.html',
  styleUrls: ['./wallet-switch-chain-modal.component.scss']
})
export class WalletSwitchChainModalComponent implements OnInit {
  @Input() logo:string;
  @Input() website:string;
  @Input() verified:boolean;
  @Input() chainId:number;
  @Input() error:boolean = false;

  chainName: string = '';

  headerConfig: IModalHeaderConfig = {
    logo: '',
    title: '',
    subtitle: {
      label: '',
      verified: false,
      logo: ''
    },
    description: ''
  }

  constructor (
      public modalController: ModalController,
      private translateService : TranslateService
  ) { }

  async ngOnInit () {
    this.chainName = await getChainName(this.chainId);
    this.headerConfig.title = this.translateService.instant('WalletConnect.switchNetwork.title', { chainName: this.chainName });
    this.headerConfig.logo = this.logo;
    this.headerConfig.subtitle.label = this.website;
    this.headerConfig.subtitle.verified = this.verified;
  }
}
