import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import moment from 'moment';
import { ArianeeService } from './providers/arianee-service/arianee.service';
import { delay, filter, take, tap } from 'rxjs/operators';
import { ScanService } from './providers/scan-service/scan-service';
import { ArianeeEventWatcherService } from './providers/arianee-event-watcher.service.ts/arianee-event-watcher.service';
import { MessagingService } from './providers/messaging-service/messaging-service';
import { HandleLinkService } from './providers/scan-service/handle-link.service/handle-link.service';
import { StatusBarService } from './providers/statusbar/statusbar-service';
import { WalletVersionService } from './providers/wallet-version-service/wallet-version.service';
import { EventLoggerService } from './providers/event-logger/event-logger-service';

import { NotificationService } from './providers/notification-service/notification.service';
import { UserService } from './providers/user-service/user.service';
import { SessionService } from './providers/session-service/session.service';
import { BackupService } from './providers/backup-service/backup.service';
import { FeatureFlipService } from './providers/feature-flip-service/feature-flip.service';
import { combineLatest } from 'rxjs';
import { ArianeeBlockchainSyncService } from './providers/arianee-blockchain-sync-service/arianee-blockchain-sync.service';
import {
  LoaderService,
  SharedLibraryTheme, StyleService,
  ToasterService,
  TranslationService
} from '@arianeeprivate/wallet-shared-components';
import { HandleInappLinkService } from './providers/scan-service/handle-link.service/handle-inapp-link.service';

declare let window: any;
declare let navigator: any;
declare var cordova: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor (
      private platform: Platform,
      private splashScreen: SplashScreen,
      private translate: TranslateService,
      private arianeeService: ArianeeService,
      private navCtrl: NavController,
      private inAppLink:HandleInappLinkService,
      private arianeeEventWatcherService:ArianeeEventWatcherService,
      private scanService: ScanService,
      private messagingService:MessagingService,
      private handleLinkService:HandleLinkService,
      private statusBar: StatusBarService,
      private walletVersionService:WalletVersionService,
      private eventLogger: EventLoggerService,
      private loaderService:LoaderService,
      private translationService: TranslationService,
      private notificationService: NotificationService,
      private userService: UserService,
      private translateService: TranslateService,
      private toasterService: ToasterService,
      private sessionService:SessionService,
      private backupService: BackupService,
      private featureFlipService: FeatureFlipService,
      private bcSyncService: ArianeeBlockchainSyncService,
      private sharedStyleService: StyleService
  ) {
    this.translate.setDefaultLang(this.translationService.defaultLanguage);
    this.translate.use(this.translationService.pickLanguageAccordingToUserLanguages());
    this.initializeApp();
    this.setCustomThemeForSharedComponents();
  }

  /**
   * Customize Shared Library Theme
   */
  setCustomThemeForSharedComponents (): void {
    const primary = getComputedStyle(document.documentElement).getPropertyValue('--color-bg-primary');
    const secondary = getComputedStyle(document.documentElement).getPropertyValue('--color-bg-secondary');
    const action = getComputedStyle(document.documentElement).getPropertyValue('--color-action-primary');

    const text = getComputedStyle(document.documentElement).getPropertyValue('--font-text');
    const title = getComputedStyle(document.documentElement).getPropertyValue('--font-title');

    const theme: SharedLibraryTheme = {
      fonts: { title, text },
      colors: { primary, secondary, action }
    };

    this.sharedStyleService.setLibraryTheme(theme);
  }

  async initializeApp () {
    this.platform.resume
      .pipe(
        tap(() => this.eventLogger.logEvent('onResume')),
        delay(500),
        tap(() => {
          this.userService.initSecureStorage();
          this.branchInit(true);
        }),
        delay(2500),
        tap(() => {
          this.messagingService.init();
        }),
        tap(() => this.bcSyncService.syncWithBlockchain(true))
      )
      .subscribe();

    try {
      await this.platform.ready();

      if (this.platform.is('cordova') && this.platform.is('ios')) {
        if (window.cordova) {
          window.cordova.exec(function () {}, function () {}, 'idfa', 'requestPermission', []);
        }
      }

      combineLatest([
        this.arianeeService.$wallet,
        this.featureFlipService.$isFeatureFlipOnce('callFaucetOnLaunch')
      ])
        .pipe(filter(([wallet, isFeatureFlip]) => isFeatureFlip))
        .subscribe(([wallet]) => wallet.requestPoa());

      this.sessionService.resetSession();
      this.userService.initSecureStorage()
        .catch(async d => {
          const translation = await this.translateService.get('Error.androidNotSecured.button')
            .toPromise();

          return this.toasterService.alert({
            backdropDismiss: false,
            message: 'Error.androidNotSecured.message',
            buttons: [
              {
                text: translation,
                cssClass: 'secondary',
                handler: () => d.secureDevice()
              }]
          });
        });

      this.userService.incrementNumberOfAppLaunch();

      this.statusBar.init();
      this.arianeeEventWatcherService.initWatch();
      this.notificationService.init();

      moment.locale(this.translate.currentLang);

      this.scanService.init();
      this.branchInit();

      this.backupService.init();

      this.arianeeService
        .$walletInitialize
        .pipe(take(1))
        .subscribe(() => {
          if (this.platform.is('cordova')) {
            this.walletVersionService.check();
            this.splashScreen.hide();
            navigator.splashscreen.hide();
          }
        });
    } catch (error) {
      this.walletVersionService.check();
      this.scanService.init();
      this.splashScreen.hide();
      navigator.splashscreen.hide();

      this.branchInit();
    }
  }

  async branchInit (resume = false) {
    let Branch;

    if (!this.platform.is('cordova')) {
      if (!window.mockBranch) {
        Object.defineProperty(window, 'mockBranch', {
          get: function () {
            return {
              initSession: () => {
                return Promise.resolve({
                  '+clicked_branch_link': false,
                  deeplink: 'foo'
                });
              }
            };
          }
        });
      }
      Branch = window.mockBranch;
    } else {
      Branch = window.Branch;
    }

    const data = await Branch.initSession();

    this.eventLogger.init(data.userId);

    if (!resume) { this.eventLogger.logEvent('appStart', ''); }

    if (data['+clicked_branch_link'] && data.deeplink) {
      this.eventLogger.logEvent('deeplink_branchio', { link: data['+clicked_branch_link'] });

      this.loaderService.showLoader();
      this.handleLinkService.handleLink(data.deeplink)
        .subscribe(objectLink => {
          if (objectLink.certificateId) {
            this.loaderService.showLoader({ label: 'productDetail' });
            this.handleLinkService.redirectWithFirstNavigation(`/tab/brand-list/product-detail/${objectLink.certificateId}/${objectLink.passphrase}/${objectLink.method}`);
          }
        });
    } else if (data['+non_branch_link']) {
      this.eventLogger.logEvent('deeplink_native', { link: data['+non_branch_link'] });
      this.loaderService.showLoader();
      this.handleLinkService.handleLink(data['+non_branch_link'])
        .subscribe(objectLink => {
          if (objectLink.certificateId) {
            this.loaderService.showLoader({ label: 'productDetail' });
            this.handleLinkService.redirectWithFirstNavigation(`/tab/brand-list/product-detail/${objectLink.certificateId}/${objectLink.passphrase}/${objectLink.method}`);
          }
        });
    }
  };
}
