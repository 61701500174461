import {Injectable} from '@angular/core';
import {fetchGasStation} from '@arianee/gas-station';

@Injectable({
  providedIn: 'root'
})
export class GasStationService {
    /**
     * Fetch gas station and fallback on 1 if error
     * @param chainId
     */
    public fetchGasStation = async (chainId: string) => {
      try {
        return await fetchGasStation(chainId);
      } catch (e) {
        return fetchGasStation(1);
      }
    }
}
