import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot, UrlSegment,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';

import { UserService } from '../providers/user-service/user.service';
import { SessionService } from '../providers/session-service/session.service';
import { ModalController } from '@ionic/angular';
import { AuthPage } from '../pages/auth/auth.page';
import { WhatPlatformService } from '../providers/what-platform-service/whatPlatformService';
import { BranchService } from '../providers/branch-service/branch-service';
import { first } from 'rxjs/operators';
import { LoaderService } from '@arianeeprivate/wallet-shared-components';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class DeferDeeplinkGuard implements CanLoad {
  constructor (private whatPlatformService: WhatPlatformService,
                private loaderService: LoaderService,
                private translateService: TranslateService,
                private branchService: BranchService) {

  }

  async canLoad (route: Route, segments: UrlSegment[]): Promise<boolean> {
    if (!this.whatPlatformService.isPWA()) {
      return true;
    } else {
      this.loaderService.showLoader();

      const title = await this.translateService.get(
        'ExportMnemonics.deepLink.title')
        .pipe(first())
        .toPromise();

      const description = await this.translateService.get(
        'ExportMnemonics.deepLink.description'
      )
        .pipe(first())
        .toPromise();

      const currentUrl = new URL(window.location.href);

      currentUrl.searchParams.append('$og_title',
        title);

      currentUrl.searchParams.append('$og_description',
        this.translateService.instant(description));

      const { url } = await this.branchService.fetchGenericDeeplink(currentUrl.href)
        .pipe(first())
        .toPromise();
      window.location.href = url;

      return false;
    }
  }
}
