import { Injectable } from '@angular/core';
import { Arianee, NETWORK } from '@arianee/arianeejs';
import { from, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { ArianeeWalletBuilder } from '@arianee/arianeejs/dist/src/core/wallet/walletBuilder';
import { UserService } from '../user-service/user.service';
import { ArianeeWallet } from '@arianee/arianeejs/dist/src/core/wallet';
import { map, mergeMap, take, tap } from 'rxjs/operators';
import { StorageService } from '../storage-service/storage.service';
import { environment } from '../../../environments/environment';
import { Platform } from '@ionic/angular';
import { ProxyService } from '../proxy-service/proxy-service';

type MapToObservable<T extends Record<string, (...args: any[]) => Promise<any>>> = {
  [P in keyof T]: T[P] extends (...args: infer P) => Promise<infer R> ? (...args: P) => Observable<R> : never
};

@Injectable({
  providedIn: 'root'
})
export class ArianeeService {
  private connectedArianee: ArianeeWalletBuilder;

  // is emited when after a wallet with a new network is initialized
  public $wallet: ReplaySubject<ArianeeWallet> = new ReplaySubject<ArianeeWallet>(1);
  // is emited when after a wallet with a new network is initialized
  public $walletInitialize: Subject<NETWORK> = new Subject();

  constructor (private userService: UserService,
               private storageService: StorageService,
               private platform:Platform,
               private arianeeHttpInterceptor:ProxyService
  ) {
    this.platform.ready().then(() => {
      this.initialize().subscribe();
    });
  }

  public switchEnvironment (network: NETWORK): Observable<NETWORK> {
    this.userService
      .chain
      .set(network);

    return this.$walletInitialize;
  }

  public switchWallet (mnemonic: string) {
    return this.userService.setMnemonic(mnemonic).pipe(
      mergeMap(() => this.initializeWallet())
    );
  }

  public switchWaitingIdentity (isWaitingIdentity: boolean): Observable<any> {
    return this.$wallet.pipe(
      take(1),
      mergeMap(wallet => {
        const currConfiguration = wallet.globalConfiguration.defaultQuery;

        currConfiguration.issuer = { waitingIdentity: isWaitingIdentity };
        wallet.globalConfiguration.setDefaultQuery(currConfiguration);
        this.$wallet.next(wallet);
        return this.$wallet;
      })
    );
  }

  public get $publicKey (): Observable<string> {
    return this.$wallet.pipe(map(wallet => wallet.address));
  }

  public get methods () {
    return this.$wallet
      .pipe(map(wallet => this.mapArianeeMethodPromiseToObservable(wallet.methods)));
  }

  public get methodsOnce () {
    return this.$wallet
      .pipe(
        map(wallet => this.mapArianeeMethodPromiseToObservable(wallet.methods)),
        take(1)
      );
  }

  private initialize = () => {
    return this.initializeArianee().pipe(
      mergeMap(() => this.initializeWallet())
    );
  }

  public refreshWallet () {
    console.info('refreshing $wallet');
    this.$wallet.pipe(
      take(1)
    ).subscribe(wallet => this.$wallet.next(wallet));
  }

  private initializeWallet = (): Observable<any> => {
    return this.userService.getMnemonic()
      .pipe(
        mergeMap(mnemonic => {
          if (environment.appType === 'web') {
            return of(this.connectedArianee.readOnlyWallet());
          } else if (mnemonic) {
            return of(this.connectedArianee.fromMnemonic(mnemonic));
          } else {
            const wallet = this.connectedArianee.fromRandomMnemonic();
            return this.userService.setMnemonic(wallet.mnemnonic)
              .pipe(map((success) => wallet));
          }
        }),
        tap(wallet => {
          const currConfiguration = wallet.globalConfiguration.defaultQuery;

          currConfiguration.advanced = {
            languages: [...navigator.languages]
          };

          currConfiguration.issuer = { waitingIdentity: environment.features.waitingIdentity, forceRefresh: false };
          wallet.globalConfiguration.setDefaultQuery(currConfiguration);
        }),
        tap(wallet => this.$wallet.next(wallet as any)),
        tap(wallet => {
          this.userService.chain
            .getOnce()
            .subscribe(network => this.$walletInitialize.next(network));
        }),
        take(1)
      );
  }

  private initializeArianee = (): Observable<any> => {
    const arianee = new Arianee();
    if (environment.appType !== 'web') {
      arianee.setStore(({
        getStoreItem: (storeKey: string) => this.storageService.nativeStorage.getItem(storeKey).then(result => {
          return result;
        }),
        hasItem: (storeKey: string): Promise<boolean> => {
          return this.storageService.nativeStorage.getItem(storeKey)
            .then(result => {
              return true;
            }).catch((e) => {
              return false;
            });
        },
        setStoreItem: (key, value) => { return this.storageService.nativeStorage.setItem(key, value); }
      }));
    }
    return this.userService
      .chain
      .get()
      .pipe(
        mergeMap(network => from(arianee.init(network, {
          transactionOptions: environment.transactionOptions,
          httpProvider: environment.blockchainProvider[network],
          blockchainProxy: {
            host: environment.blockchainProxy.host,
            enable: environment.blockchainProxy.enable
          },
          httpInterceptor: {
            httpRequestInterceptor: this.arianeeHttpInterceptor.requestInterceptor
          }
        }))),
        tap(connectedArianee => { this.connectedArianee = connectedArianee; })
      );
  }

  private mapArianeeMethodPromiseToObservable = <T extends Record<string, (...args: any[]) => any>>(methods: T) => {
    return Object.keys(methods).reduce((acc, key) => {
      acc[key] = function (...args: any[]) {
        return from(methods[key].call(this, ...args));
      };

      if (key === 'createActionJWTProofLink') {
        acc[key] = function (...args: any[]) {
          return of(methods[key].call(this, ...args));
        };
      }
      return acc;
    }, {} as Record<string, (...args: any[]) => Observable<any>>) as any as MapToObservable<T>;
  };
}
