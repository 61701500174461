import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { ArianeeService } from '../arianee-service/arianee.service';
import { UserService } from '../user-service/user.service';
import { CloudSettings } from '@ionic-native/cloud-settings/ngx';

@Injectable({
  providedIn: 'root'
})
export class CloudSettingsService {
  constructor (
    private arianeeService: ArianeeService,
    private userService: UserService,
    private cloudSettings: CloudSettings
  ) { }

   key = 'mnemonics';

   async backupMnemonic () {
     const registeredMnemonics = await this.getBackups();
     const mnemonic = await this.userService.getEncryptedMnemonic().pipe(take(1)).toPromise();
     const data = { mnemonic: mnemonic };
     const address = await this.arianeeService.$publicKey.pipe(take(1)).toPromise();
     const now = Date.now();

     registeredMnemonics[`backup-${address}-${now}`] = data;

     return this.cloudSettings.save({ backups: registeredMnemonics });
   }

   public async getBackups () {
     const backups = await this.cloudSettings.load().catch((e) => { console.error('The backup file does not exist'); });
     if (backups) {
       return backups.backups || {};
     }

     return {};
   }

   public async getBackupsId ():Promise<{id:string, date:Date}[]> {
     const backups = await this.getBackups();
     if (backups) {
       return Object.keys(backups).map((item) => {
         const backupId:any = { id: item };
         backupId.date = new Date(+item.split('-').pop());
         return backupId;
       });
     }

     return [];
   }

   async getActualWalletBackup () {
     const address = await this.arianeeService.$publicKey.pipe(take(1)).toPromise();
     const backupsId = await this.getBackupsId();
     return backupsId.find((item) => item.id.split('-')[1] === address);
   }

   public async getMnemonic (backupId) {
     const backups = await this.getBackups();
     return backups[backupId];
   }

   public clearBackup () {
     this.cloudSettings.save({}, true);
   }
}
