import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { WhatPlatformService } from '../providers/what-platform-service/whatPlatformService';
import { ArianeeService } from '../providers/arianee-service/arianee.service';
import { mergeMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IsPWAAndSwitchNetworkGuard implements CanActivate {
  constructor (private router: Router,
              private whatPlatformService: WhatPlatformService,
              private arianeeService: ArianeeService
  ) {
  }

  public async canActivate (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    if (this.whatPlatformService.isPWA()) {
      try {
        const wallet = await this.arianeeService.$wallet
          .pipe(take(1))
          .toPromise();

        wallet.utils.readLink(window.location.href);
      } catch (e) {
        if (e.chain) {
          console.warn('chain', e.chain);

          // if no chain, let it go! It makes it work in localhost
          if (e.chain) {
            console.warn('handleLink ##  Switching chain');
            await this.arianeeService.switchEnvironment(e.chain)
              .pipe(
                take(1)
              ).toPromise();
          }
        }
      }
      return true;
    } else {
      return false;
    }
  }
}
