import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';

import {UserService} from '../providers/user-service/user.service';
import {SessionService} from '../providers/session-service/session.service';
import {ModalController} from '@ionic/angular';
import {FakeLoginPage} from '../pages/fakeLogin/fakeLogin.page';
import {FeatureFlipService} from '../providers/feature-flip-service/feature-flip.service';

@Injectable({
  providedIn: 'root'
})
export class FakeLoginGuard implements CanActivate {
    private fakeLoginModal;

    constructor (private userService: UserService, private router: Router,
                private sessionService: SessionService,
                private featureFlipService:FeatureFlipService,
                private modal: ModalController) {
    }

    public async isLoggedIn (): Promise<boolean> {
      const isFeatureFlipOn = await this.featureFlipService
        .$isFeatureFlipOnce('fakeLogin')
        .toPromise();

      if (isFeatureFlipOn && this.userService.hasFakeLoggedIn === false) {
        await this.presentModalAndWaitLogin();
        this.userService.hasFakeLoggedIn = true;
      };

      return true;
    }

    public canActivate (
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.isLoggedIn();
    }

    private async presentModalAndWaitLogin () {
      if (this.fakeLoginModal === undefined) {
        this.fakeLoginModal = await this.modal.create({
          component: FakeLoginPage,
          cssClass: 'modal--full_screen'
        });
        await this.fakeLoginModal.present();
        await this.fakeLoginModal.onDidDismiss();
        this.fakeLoginModal = undefined;
      }

      return true;
    }
}
