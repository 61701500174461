import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map, mergeMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ArianeeService } from '../arianee-service/arianee.service';
import { EventLoggerService } from '../event-logger/event-logger-service';

@Injectable({
  providedIn: 'root'
})
export class BranchService {
    public userId: string;

    private simpleCacheObs:{[key:string]:Subject<any>}={};

    constructor (
      private httpClient: HttpClient,
      private arianeeService:ArianeeService,
      private eventLoggerService: EventLoggerService
    ) {
      this.userId = this.eventLoggerService.getUserId();
    }

    // eslint-disable-next-line camelcase
    public fetchDeepLinkForPassport = (urlToRedirectTo:string): Observable<{ url: string, userId: string, event_properties: { certificateId: string, network: string } }> => {
      const key = urlToRedirectTo;
      if (!this.simpleCacheObs[key]) {
        this.simpleCacheObs[key] = new Subject<any>();
        const url = `${environment.deeplinkURL}/passport`;
        this.arianeeService.$wallet
          .pipe(
            mergeMap(arianeeLink => this.httpClient
              .post<any>(url, {
                userId: this.userId,
                arianeeLink: urlToRedirectTo
              }))
          ).subscribe(d => {
            this.simpleCacheObs[key].next(d);
          });
      }
      return this.simpleCacheObs[key];
    };

    // {$og_description, $og_image_url, $og_title}
    /**
     * Fetch generic deeplink
     * @param arianeeLink
     */
    public fetchGenericDeeplink (arianeeLink: string): Observable<{ url: string }> {
      if (!this.simpleCacheObs[arianeeLink]) {
        this.simpleCacheObs[arianeeLink] = new Subject<any>();
        const url = `${environment.deeplinkURL}/generic`;
        this.httpClient
          .post<any>(url, {
            userId: this.userId,
            arianeeLink
          }).subscribe(d => {
            this.simpleCacheObs[arianeeLink].next(d);
          });
      }

      return this.simpleCacheObs[arianeeLink];
    }
}
