import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { UserService } from '../providers/user-service/user.service';
import { SessionService } from '../providers/session-service/session.service';
import { ModalController, Platform } from '@ionic/angular';
import { LoaderService } from '@arianeeprivate/wallet-shared-components';
import { WhatPlatformService } from '../providers/what-platform-service/whatPlatformService';
import { BranchService } from '../providers/branch-service/branch-service';
import { EventLoggerService } from '../providers/event-logger/event-logger-service';
import { map, tap } from 'rxjs/operators';
import {getWcLinkFromDeeplink} from "../providers/scan-service/handle-link.service/helpers/getWcLink";

@Injectable({
  providedIn: 'root'
})
export class SkipPWAGuard implements CanActivate {
  constructor (private userService: UserService, private router: Router,
               private sessionService: SessionService,
               private loaderService:LoaderService,
               private platform: Platform,
               private whatPlatformService:WhatPlatformService,
               private branchService:BranchService,
               private eventLogger:EventLoggerService,
               private modal:ModalController) {
  }

  public canActivate (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.loaderService.showLoader();

    const isSkipOption = route.queryParamMap.get('s') !== null;
    const isPWA = this.whatPlatformService.isPWA();
    const isNotDesktop = !this.platform.is('desktop');
    if(!!getWcLinkFromDeeplink(window.location.href) && isPWA){
      return this.branchService.fetchGenericDeeplink(window.location.href)
        .pipe(tap(d => {
            window.location.replace(d.url);
          }),
          map(d => false)
        );
    }

    if (isSkipOption && isPWA && isNotDesktop) {
      try {
        const certificateIdAndpassphrase = route.paramMap.get('certificateIdAndpassphrase');

        const [certificateId, passphrase] = certificateIdAndpassphrase.split(',');

        this.eventLogger.logEvent('deeplinkFront_SkipPWA',
          { certificateId });

        return this.branchService.fetchDeepLinkForPassport(window.location.href)
          .pipe(tap(d => {
            window.location.replace(d.url);
          }),
          map(d => false)
          );
      } catch (e) {
        return true;
      }
    }

    return true;
  }
}
