import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { PrivacyPolicyPopover } from './privacy-policy-popover/privacy-policy-popover.component';
import { NetworkWatemarkComponent } from './network-watermark/network-watemark.component';
import { ImportPopoverComponent } from './import-popover/import-popover.component';
import { NotiOptionComponent } from './noti-option/noti-option.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MomentModule } from 'ngx-moment';
import { CommonComponentsModule, PipeModule } from '@arianeeprivate/wallet-shared-components';
import { DeletePassportComponent } from './delete-passport/delete-passport.component';
import {ExportMnemonicComponent} from "./export-mnemonic/export-mnemonic.component";

const components = [
  PrivacyPolicyPopover,
  NetworkWatemarkComponent,
  ImportPopoverComponent,
  NotiOptionComponent,
  DeletePassportComponent,
  ExportMnemonicComponent

];
@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    MomentModule,
    FormsModule,
    PipeModule,
    CommonComponentsModule
  ],
  declarations: components,
  exports: components,
  entryComponents: components
})
export class ComponentsModule { }
