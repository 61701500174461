import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AmplitudeClient, getInstance } from 'amplitude-js';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { ProfileService } from '../profile-service/profile.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ProxyService } from '../proxy-service/proxy-service';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class EventLoggerService {
  private client: AmplitudeClient;
  private queueLogs:Array<{eventName, params}>=[];
  private userId: any;

  constructor (
    private platform: Platform,
    private appVersion: AppVersion,
    private profileService:ProfileService,
    private activatedRoute:ActivatedRoute,
    private arianeeInterceptorService:ProxyService
  ) {

  }

  public init = async (userId?) => {
    const instance = getInstance();

    instance.init('afb728e29bc8aeb37dab8e13f353c3fd');

    if (!userId) {
      if (instance.options.userId) {
        this.userId = instance.options.userId;
      } else {
        this.userId = this.uuid();
      }
    } else {
      this.userId = userId;
    }

    if (!instance.options.userId) {
      instance.setUserId(this.userId);
    }

    const amplitudeEndpoint = 'https://api2.amplitude.com';
    instance.options.apiEndpoint = amplitudeEndpoint.replace('https://', '');

    this.profileService.getProfile()
      .subscribe(profile => {
        instance.setVersionName(profile.versionCode.toString());
        instance.setUserProperties({ ...profile });

        this.client = instance;
        this.queueLogs.forEach((i) => this.logEvent(i.eventName, i.params));

        this.queueLogs = [];
      });
  };

  private uuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0;
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  public getUserId () {
    return this.userId;
  }

  private shouldBeMock () {
    return this.platform.is('desktop') || this.platform.is('mobileweb');
  }

  public logScreen = (screenName: string, params: any = {}) => {
    this.logEvent('SCREEN_' + screenName, params);
  }

  public logEvent=async (eventName: string, params?: any) => {
    if (environment.production) {
      if (!this.client) {
        this.queueLogs.push({ eventName, params });
      } else {
        const queryParmas = await this.activatedRoute.queryParams.pipe(take(1)).toPromise();

        this.client.logEvent(eventName, {
          ...queryParmas,
          ...params
        });
      }
    } else {
      console.info(eventName, params);
    }
  }
}
