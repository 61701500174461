
export const messages: any = [
  {
    body: 'test...',
    date: '2019-11-28T12:59:08.412Z',
    payload:
        { msg: 'Mock message 1', tokenId: '34204364', title: 'test', content: 'test', 'com.batch': { i: 'identifier' } }
  },
  {
    body: 'mock message 1',
    date: '2019-11-28T14:59:08.412Z',
    payload:
      { msg: 'Mock message 2', tokenId: '34204364', title: 'test', content: 'test', 'com.batch': { i: 'identifier2' } }
  },
  {
    body: 'mock message 3',
    date: '2019-11-28T13:39:08.412Z',
    payload:
        { msg: 'Mock message 2', tokenId: '34204364', title: 'test', content: 'test', 'com.batch': { i: 'identifier2' } }
  },
  {
    body: 'mock message 4',
    date: '2019-09-28T13:59:08.412Z',
    payload:
        { msg: 'Mock message 2', tokenId: '34204364', title: 'test', content: 'test', 'com.batch': { i: 'identifier2' } }
  },
  {
    body: 'mock message 5',
    date: '2020-11-28T13:59:08.412Z',
    payload:
        { msg: 'Mock message 2', tokenId: '34204364', title: 'test', content: 'test', 'com.batch': { i: 'identifier2' } }
  },
  {
    body: 'mock message 6',
    date: '2019-12-17T01:59:08.412Z',
    payload:
        { msg: 'Mock message 2', tokenId: '34204364', title: 'test', content: 'test', 'com.batch': { i: 'identifier2' } }
  },
  {
    body: 'mock message 7',
    date: '2019-12-18T16:59:08.412Z',
    payload:
        { msg: 'Mock message 2', tokenId: '34204364', title: 'test', content: 'test', 'com.batch': { i: 'identifier2' } }
  }
];
