import { Injectable } from '@angular/core';
import { ArianeeService } from '../arianee-service/arianee.service';

import { NavController } from '@ionic/angular';
import { ArianeeBlockchainSyncService } from '../arianee-blockchain-sync-service/arianee-blockchain-sync.service';
import { EventLoggerService } from '../event-logger/event-logger-service';
import { VibrationService } from '../vibration-service/vibration.service';
import { NotificationService } from '../notification-service/notification.service';
import { FeatureFlipService } from '../feature-flip-service/feature-flip.service';
import { ToasterService, Events } from '@arianeeprivate/wallet-shared-components';
import { ArianeeWallet } from '@arianee/arianeejs/dist/src/core/wallet';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ArianeeEventWatcherService {
  oldWallet: ArianeeWallet;

  public $transferToReceived : Subject<any> = new Subject();

  constructor (private arianeeService:ArianeeService,
               private toasterService:ToasterService,
               private navController:NavController,
               private arianeeBlockchainSyncService:ArianeeBlockchainSyncService,
               private events: Events,
               private eventLogger: EventLoggerService,
               private vibrationService: VibrationService,
               private notificationService: NotificationService,
               private featureFlipSerivce: FeatureFlipService,
               private loggerService:EventLoggerService

  ) {
  }

    public initWatch = async () => {
      const isBlockChainOff = await this.featureFlipSerivce
        .$isFeatureFlipOnce('blockChainWatcherEventOff')
        .toPromise();

      if (isBlockChainOff === false) {
        this.arianeeService.$wallet
          .subscribe(wallet => {
            if (this.oldWallet) {
              this.oldWallet.watch.removeAllListeners('TransferTo');
              this.oldWallet.watch.removeAllListeners('TransferFrom');
              this.oldWallet.watch.removeAllListeners('MessageReceive');
            }

            wallet.watch.on('TransferTo', (event) => {
              this.loggerService.logEvent('watcher_TransferTo');

              this.vibrationService.vibrateRepeat();
              this.toasterService.show({
                message: 'BlockChainEventToaster.TransferTo',
                position: 'top',
                duration: 2500
              });
              this.arianeeBlockchainSyncService.syncAllCertificate({});
              this.$transferToReceived.next(event);
            });

            wallet.watch.on('TransferFrom', () => {
              this.loggerService.logEvent('watcher_TransferFrom');
              this.events.publish('TransferFrom');
              this.vibrationService.vibrateRepeat();
              this.toasterService.show({
                message: 'BlockChainEventToaster.TransferFrom',
                position: 'top',
                duration: 2500
              });
              this.navController.navigateRoot('/tab/brand-list');
              this.arianeeBlockchainSyncService.syncAllCertificate({});
            });

            wallet.watch.on('MessageReceive', (events) => {
              this.loggerService.logEvent('watcher_MessageReceive');

              setTimeout(() => {
                this.arianeeService.refreshWallet();
              }, 5000);
              setTimeout(() => {
                this.toasterService.show({
                  message: 'Notification.newMessageToaster',
                  position: 'top',
                  duration: 2500
                });
              }, 15000);
            });

            this.oldWallet = wallet;
          });
      }
    }
}
