import { Component, Input, OnInit } from '@angular/core';
import { IModalHeaderConfig } from '../components/modal-header/modal-header.component';
import { ModalController } from '@ionic/angular';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-wallet-error-modal',
  templateUrl: './wallet-error-modal.component.html',
  styleUrls: ['./wallet-error-modal.component.scss']
})
export class WalletErrorModalComponent implements OnInit {
  @Input() status!: 'SUCCESS' | 'ERROR';
  @Input() logo:string;
  @Input() website:string
  headerConfig: IModalHeaderConfig = {
    logo: '',
    title: 'An Error Occurred',
    subtitle: {
      label: '',
      verified: false,
      logo: ''
    },
    description: 'Please try again.'
  }

  constructor (
      public modalController: ModalController,
      public translateService: TranslateService
  ) { }

  ngOnInit () {

    this.headerConfig.title = this.translateService.instant('WalletConnect.error.title');
    this.headerConfig.title = this.translateService.instant('WalletConnect.error.description');
    if (this.status && this.status === 'ERROR') {
      this.headerConfig.logo = './assets/imgs/error-icon.png';
    }
  }
}
