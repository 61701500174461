import {Injectable} from '@angular/core';
import {WalletConnectRequestPayload} from '../../models/walletConnect';
import {ethers, Wallet} from 'ethers';
import {get} from 'lodash';
import {getProviderOfChain, SwitchEthereumChainFallbackRpc} from './chain-utils';
import {TransactionRequest} from "@ethersproject/abstract-provider";
import {take} from "rxjs/operators";
import {ArianeeService} from "../arianee-service/arianee.service";

@Injectable({
  providedIn: 'root'
})

export class WalletConnectRequestHandlerService {
  private wallet:Wallet;
  public chainId:number = 1;

  constructor (
    private arianeeService: ArianeeService
  ) {

  }

  public  init = async()=>{
    const wallet = await this.arianeeService.$wallet
      .pipe(
        take(1)
      )
      .toPromise()

    this.wallet = ethers.Wallet.fromMnemonic(wallet.mnemnonic);
    return this.trySwitchChain(this.chainId);
  }

  public handleRequest = async (payload:WalletConnectRequestPayload) => {
    let result;
    switch (payload.method) {
      case 'eth_sign':
        result = this.signMessage(payload.params[1]);
        break;
      case 'personal_sign':
        result = this.signMessage(payload.params[0]);
        break;
      case 'eth_signTypedData':
        result = this.signTypedData(payload.params[1]);
        break;
      case 'wallet_switchEthereumChain':
        result = await this.trySwitchChain(parseInt(payload.params[0].chainId, 16), get(payload.params[0], 'fallbackRpc', null));
        break;
      case 'eth_signTransaction':
        result = await this.signTransaction(payload.params[0]);
        break;
      case 'eth_sendTransaction':
        result = await this.sendTransaction(payload.params[0]);
        break;
    }

    return result;
  }

  private signMessage (params:string):Promise<string> {
    let data:string|Uint8Array = params;
    if (params.startsWith('0x')) {
      data = ethers.utils.arrayify(params);
    }
    return this.wallet.signMessage(data);
  }

  private signTypedData (request:string):Promise<string> {
    let parsedRequest:any = request;
    if(typeof parsedRequest === 'string') {
      parsedRequest = JSON.parse(request);
    }
    const { domain, types, message } = parsedRequest;
    if (types.EIP712Domain) {
      delete types.EIP712Domain;
    }
    return this.wallet._signTypedData(domain, types, message);
  }

  /**
   * Switch to the chain with id chainId if possible
   * @param chainId chain to switch to
   * @param fallbackRpc a fallback rpc to use if no known rpc provider exists
   * @returns Returns the chainId in hex format if switch succeeded, throws otherwise
   */
  public async trySwitchChain (chainId: number, fallbackRpc?: SwitchEthereumChainFallbackRpc) : Promise<string | null> {
    const jsonRpcProvider = await getProviderOfChain(chainId, fallbackRpc);
    if (!jsonRpcProvider) throw new Error('No provider found for chain ' + chainId);
    this.wallet = this.wallet.connect(new ethers.providers.JsonRpcProvider(jsonRpcProvider));
    this.chainId = chainId;
    return '0x' + chainId.toString(16);
  }


  private getNonce():Promise<number> {
    return this.wallet.getTransactionCount('pending');
  }

  private async formatTransaction(payload):Promise<TransactionRequest>{
    const currentNonce = await this.getNonce();

    return {
      to: payload.to,
      gasLimit: ethers.BigNumber.from(payload.gas || payload.gasLimit),
      gasPrice: ethers.utils.parseUnits(""+payload.gasPrice, 'gwei'),
      data: payload.data,
      value: payload.value,
      chainId: this.chainId,
      nonce : payload.nonce || currentNonce
    }
  }

  public async signTransaction(payload:any):Promise<string> {
    const tx = await this.formatTransaction(payload);
    return this.wallet.signTransaction(tx);
  }

  public async sendTransaction(payload:any):Promise<string> {
    const tx = await this.formatTransaction(payload);
    const transaction = await this.wallet.sendTransaction(tx);
    const receipt= await transaction.wait(1);
    return JSON.stringify(receipt);
  }




}
