import { Injectable } from '@angular/core';
import { ArianeeService } from '../arianee-service/arianee.service';
import { map, mergeMap, take, tap } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { cloneDeep, isEqual } from 'lodash';
import { CertificateSummary } from '@arianee/arianeejs/dist/src/core/wallet/certificateSummary';

@Injectable({
  providedIn: 'root'
})
export class ArianeeBlockchainSyncService {
  constructor (private arianeeService:ArianeeService) {
  }

  private hasAlreadySynced=false;

  /**
     * Return true if certificate content AND identity are identical
     * @param certificate1
     * @param certificate2
     */
  private areCertificateIdentical (certificate1:CertificateSummary, certificate2:CertificateSummary):boolean {
    const hasSameCertificateId = certificate1.certificateId.toString() === certificate2.certificateId.toString();
    const hasIssuerCertificateChanged = certificate1.issuer.imprint === certificate2.issuer.imprint;
    const hasContentCertificateChanged = certificate1.content.imprint === certificate2.content.imprint;

    return hasSameCertificateId && hasIssuerCertificateChanged && hasContentCertificateChanged;
  }

  /**
     *
     * Sync with blockchain only if it has not been made before or force =true
    * It will sync identities && content of all owner's certificates
     * @param force
     */
  public syncWithBlockchain=(force = false) => {
    if (this.hasAlreadySynced === false || force) {
      this.hasAlreadySynced = true;
      return this.syncAllCertificate();
    }
  }

  public syncAllCertificate (query: {
      issuer?: { forceRefresh: boolean },
      content?: { forceRefresh: boolean },
      events?:{forceRefresh: boolean}
    } =
  {
    issuer: { forceRefresh: true },
    content: { forceRefresh: true },
    events: { forceRefresh: true }
  }
  ):void {
    this.arianeeService.$wallet.pipe(
      take(1),
      mergeMap(wallet => {
        // Get the previous used configuration
        const currentConfig = cloneDeep(wallet.globalConfiguration.getMergedQuery());

        // Be sure to NOT update issuer to be sure to get it from cache
        const issuerOldConfig = {
          ...currentConfig.issuer as any,
          forceRefresh: false
        };

        const contentOldConfig = {
          ...currentConfig.issuer as any,
          forceRefresh: false
        };

        // Retrieve cached certificates
        const $oldCertificates:Observable<CertificateSummary[]> = this.arianeeService.methods
          .pipe(
            mergeMap(method => method.getMyCertificates({
              issuer: issuerOldConfig,
              content: contentOldConfig
            }, false)),
            take(1)
          );

        /* Fetch according to asked sync query
            For example to update identity on launch
         */
        const newConfig = cloneDeep(wallet.globalConfiguration.getMergedQuery(query));

        const $newCertificates:()=>Observable<CertificateSummary[]> = () => from(wallet.methods
          .getMyCertificates(newConfig, true));
        return $oldCertificates
          .pipe(
            mergeMap(oldCerti => $newCertificates()
              .pipe(
                map(newCerti => [newCerti, oldCerti])
              )
            ));
      }),
      map(([refreshCertificates, oldCertificates]) => {
        const refreshCertIds = refreshCertificates.map(c => c.certificateId).sort();
        const oldCertIds = oldCertificates.map(c => c.certificateId).sort();

        if (!isEqual(refreshCertIds, oldCertIds)) {
          return false;
        }

        const areAllTheSame = !refreshCertificates
          .map(refreshCert => {
            const oldCert = oldCertificates.find(cert => cert.certificateId === refreshCert.certificateId);
            return this.areCertificateIdentical(oldCert, refreshCert);
          })
          // if they are not identical, there is a false in array
          .includes(false);

        return areAllTheSame;
      }),
      tap(areAllTheSame => {
        if (!areAllTheSame) {
          this.arianeeService.refreshWallet();
        }
      })
    ).subscribe();
  }
}
