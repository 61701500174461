import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, pipe, ReplaySubject } from 'rxjs';

import { Message } from '@arianee/arianeejs/dist/src/core/wallet/certificateSummary/certificateSummary';
import { ArianeeService } from '../arianee-service/arianee.service';
import { map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { CertificateSummary } from '@arianee/arianeejs/dist/src/core/wallet/certificateSummary';
import moment from 'moment';

import { BaiduResponse, NotificationData } from '@ionic-native/baidu-push/ngx';

import { get } from 'lodash';

import {
  DisplayMapperService,
  EnrichedNotification,
  Notification,
  NotificationProvider,
  ToasterService
} from '@arianeeprivate/wallet-shared-components';

@Injectable({
  providedIn: 'root'
})
export class DecentralizedMessageService implements NotificationProvider {
  public messageList: EnrichedNotification[];
  public $message = new ReplaySubject<any[]>(1);

  constructor (
    private arianeeService: ArianeeService,
    private toasterService: ToasterService,
    private displayMapperService:DisplayMapperService
  ) {
  }

  public init () {
    this.arianeeService.$wallet.pipe(switchMap(d => this.getNotifications())).subscribe();
  }

  /**
   * Update read status if not already true
   * @param tokenId
   * @param messageId
   */
  public markAsRead (tokenId, messageId):Observable<boolean> {
    const { isRead } = this.messageList.find(d => {
      return d.tokenId === tokenId && d.id === messageId;
    });

    if (isRead === false) {
      this.messageList.find(d => {
        return d.tokenId === tokenId && d.id === messageId;
      }).isRead = true;

      this.pushNotifications(this.messageList);

      return this.arianeeService.methodsOnce
        .pipe(
          mergeMap(methods => methods.markAsRead(messageId))
        );
    }

    return of(false);
  }

  public pushNotifications (notifications: EnrichedNotification[]) {
    this.$message.next([...notifications]);
  }

  private enrichedNotification (notification): Observable<EnrichedNotification> {
    const { tokenId } = notification;
    return this.arianeeService.methodsOnce
      .pipe(
        mergeMap(method => method.getCertificate(tokenId, undefined, { content: true, issuer: true })),
        map((certificate: CertificateSummary) => this.displayMapperService.mapEnrichedNotification(notification, certificate))
      );
  }

  private getNotifications () {
    return this.arianeeService.methodsOnce
      .pipe(
        mergeMap(methods => {
          return methods.getMyMessages(
            {
              query: {
                advanced: {
                  languages: [...navigator.languages]
                }
              }
            }
          );
        }),
          // @ts-ignore
          map((messages: Message[]) => messages.filter((message) => message !== undefined && message !== null)),
        map((messages: Message[]) => messages.map(message => this.mapDecentralizedMessage(message))),
        mergeMap((messages) => {
          if (messages.length > 0) {
            return forkJoin(
              [...messages
                .filter(message => message !== undefined && message !== null)
                .map((notification: Notification) => this.enrichedNotification(notification))]

            );
          } else {
            return of([]);
          }
        }),
        tap((messages) => {
          this.messageList = messages.filter(message => message !== undefined && message !== null);
          this.pushNotifications(this.messageList);
        })
      );
  }

  public getMessage (messageId) {
    return this.arianeeService.methodsOnce
      .pipe(
        mergeMap(methods => methods.getMessage({
          messageId: messageId,
          query: {
            advanced: {
              languages: [...navigator.languages]
            }
          }
        })),
        map(message => this.mapDecentralizedMessage(message)),
        mergeMap(message => this.enrichedNotification(message))
      );
  }

  public addMessage (messageId) {
    return this.getMessage(messageId)
      .pipe(
        tap((message) => {
          this.messageList.push(message);
          this.pushNotifications(this.messageList);
        })
      );
  }

  public messageToaster (message: EnrichedNotification) {
    this.toasterService.show({ message: 'Notification.newMessageToaster', position: 'top', duration: 2500 });
  }

  public batchPushNotificationHandler (e) {
    return this.arianeeService.methodsOnce
      .pipe(
        mergeMap(methods => methods.getMessage({ messageId: e.payload.messageId })),
        map((message: Message) => this.mapDecentralizedMessage(message)),
        mergeMap((notification: Notification) => this.enrichedNotification(notification)),
        tap((message) => {
          this.messageList.push(message);
          this.pushNotifications(this.messageList);
          this.messageToaster(message);
        }),
        take(1)
      ).subscribe();
  }

  public baiduPushNotificationHandler () {
    return pipe(
      mergeMap((notification: BaiduResponse<NotificationData>) => {
        const properties = JSON.parse(notification.data.customContentString);
        return this.arianeeService.methodsOnce.pipe(
          mergeMap(methods => methods.getMessage({ messageId: properties.messageId }))
        );
      }),
      map((message: Message) => this.mapDecentralizedMessage(message)),
      mergeMap((notification: Notification) => this.enrichedNotification(notification)),
      tap((message) => {
        this.messageList.push(message);
        this.pushNotifications(this.messageList);
        this.messageToaster(message);
      }),
      take(1)
    );
  }

  private mapDecentralizedMessage (message): Notification {
    return {
      tokenId: message.certificateId,
      time: moment(message.timestamp).toISOString(),
      message: get(message.content, 'data.content'),
      title: get(message.content, 'data.title'),
      isRead: message.isRead,
      id: message.messageId,
      issuer: message.issuer,
      externalContents: get(message.content, 'data.externalContents')
    };
  }
}
