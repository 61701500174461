import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { SecureStorage } from '@ionic-native/secure-storage/ngx';
import { PreviewAnyFile } from '@ionic-native/preview-any-file/ngx';

import { ComponentsModule } from './components/components.module';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { Vibration } from '@ionic-native/vibration/ngx';

import { MomentModule } from 'ngx-moment';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { AppAvailability } from '@ionic-native/app-availability/ngx';

import { IntroPageModule } from './pages/intro/intro.module';
import { AuthPageModule } from './pages/auth/auth.module';
import { SentryErrorHandler } from './providers/sentry-service/sentry-service';
import { NFC } from '@ionic-native/nfc/ngx';
import { BaiduPush } from '@ionic-native/baidu-push/ngx';
import { CloudSettings } from '@ionic-native/cloud-settings/ngx';

import { CameraPreview } from '@ionic-native/camera-preview/ngx';
import { QRScanner } from '@ionic-native/qr-scanner/ngx';
import { CommonComponentsModule, BackUpWarnModalComponent } from '@arianeeprivate/wallet-shared-components';
import { CommonModule } from '@angular/common';
import { FakeLoginModule } from './pages/fakeLogin/fakeLogin.module';
import {
  ConnectToWebsiteModalComponent
} from './components/wallet-connect-modals/connect-to-website-modal/connect-to-website-modal.component';
import {
  ModalHeaderComponent
} from './components/wallet-connect-modals/components/modal-header/modal-header.component';
import {
  ModalListWithIconComponent
} from './components/wallet-connect-modals/components/modal-list-with-icon/modal-list-with-icon.component';
import {
  WalletConnectedModalComponent
} from './components/wallet-connect-modals/wallet-connected-modal/wallet-connected-modal.component';
import {
  WalletConnectionLoaderComponent
} from './components/wallet-connect-modals/wallet-connection-loader/wallet-connection-loader.component';
import {
  ScanCompatibleModalComponent
} from './components/wallet-connect-modals/scan-compatible-modal/scan-compatible-modal.component';
import {
  WalletSignMessageModalComponent
} from './components/wallet-connect-modals/wallet-sign-message-modal/wallet-sign-message-modal.component';
import {
  WalletSwitchChainModalComponent
} from './components/wallet-connect-modals/wallet-switch-chain-modal/wallet-switch-chain-modal.component';
import {
  WalletTransactionModalComponent
} from './components/wallet-connect-modals/wallet-transaction-modal/wallet-transaction-modal.component';
import {
  WalletErrorModalComponent
} from './components/wallet-connect-modals/wallet-error-modal/wallet-error-modal.component';

export function createTranslateLoader (http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

const providersArray:any = [
  AndroidPermissions,
  CameraPreview,
  StatusBar,
  SplashScreen,
  SecureStorage,
  NativeStorage,
  PreviewAnyFile,
  FingerprintAIO,
  InAppBrowser,
  SocialSharing,
  Vibration,
  AppVersion,
  Clipboard,
  AppAvailability,
  NFC,
  BaiduPush,
  CloudSettings,
  QRScanner,
  { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  { provide: ErrorHandler, useClass: SentryErrorHandler }
];

@NgModule({
  declarations: [
    AppComponent,
    ModalHeaderComponent,
    ModalListWithIconComponent,
    ConnectToWebsiteModalComponent,
    WalletConnectedModalComponent,
    WalletConnectionLoaderComponent,
    ScanCompatibleModalComponent,
    WalletSignMessageModalComponent,
    WalletTransactionModalComponent,
    WalletErrorModalComponent,
    WalletSwitchChainModalComponent
  ],
  entryComponents: [
    ConnectToWebsiteModalComponent,
    WalletConnectedModalComponent,
    WalletConnectionLoaderComponent,
    ScanCompatibleModalComponent,
    WalletSignMessageModalComponent,
    WalletSwitchChainModalComponent,
    WalletTransactionModalComponent,
    WalletErrorModalComponent
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ swipeBackEnabled: false }),
    AppRoutingModule,
    TranslateModule.forRoot({
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    HttpClientModule,
    ComponentsModule,
    MomentModule,
    IntroPageModule,
    AuthPageModule,
    FakeLoginModule,
    CommonComponentsModule,
    CommonModule
  ],
  providers: providersArray,
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
