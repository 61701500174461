const storageKeys = {
  onBoardingStatus: 'onBoardingStatus',
  privacyPolicyStatus: 'privacyPolicyStatus',
  mnemonic: 'mnemonic',
  mnemonicV1: 'encryptedKey',
  pinCode: 'pinCode',
  hasDevTools: 'hasswitchselectinsettings',
  hasBackupStatusKey: 'hasBackupStatusKey',
  autoBackup: 'autoBackup',
  userNetwork: 'userNetwork',
  featureFlips: 'featureFlips',
  numberOfAppLaunch: 'numOfAppLaunch',
  hasBackup: 'hasBackup'
};

export { storageKeys };
