import { Injectable } from '@angular/core';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { filter } from 'rxjs/operators';
import { ResolveEnd, Router } from '@angular/router';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class StatusBarService {
  constructor (
    private statusBar: StatusBar,
    private router: Router,
    private platform: Platform
  ) {
  }

  private specificRoutes = [
    { url: '/tab/scan', statusBarType: 'blackBackground' },
    { url: '/tab/scan-full-page', statusBarType: 'blackBackground' },
    { url: '/tab/scan-edgyn', statusBarType: 'blackBackground' },
    { url: '/tab/noti', statusBarType: 'blackBackground' },
    { url: '/chat', statusBarType: 'blackBackground' },
    { url: '/notification', statusBarType: 'blackBackground' },
    { url: '/tab/brand-list/product-detail', statusBarType: 'transparentBackground' },
    { url: '/brand-page', statusBarType: 'blackBackground' }

  ];

  private isAndroid;

  public init () {
    this.isAndroid = this.platform.is('android');
    this.router.events
      .pipe(
        filter(event => event instanceof ResolveEnd)
      )
      .subscribe((event: ResolveEnd) => {
        let change = false;

        if (this.isAndroid) {
          this.statusBar.overlaysWebView(false);
          this.statusBar.backgroundColorByHexString('#000000');
          this.statusBar.styleBlackOpaque();
        } else {
          this.statusBar.overlaysWebView(true);
          this.specificRoutes.forEach((value) => {
            if (event.url.includes(value.url)) {
              this.changeStatusBar(value.statusBarType);
              change = true;
            }
          });
          if (!change) {
            this.changeStatusBar();
          }
        }
      });
  }

  public changeStatusBar (template?: string) {
    switch (template) {
      case 'transparentBackground':
        this.statusBar.styleBlackTranslucent();

        break;
      case 'blackBackground':
        this.statusBar.styleBlackOpaque();
        this.statusBar.backgroundColorByHexString('#000000');

        break;
      default:
        this.statusBar.backgroundColorByHexString('#ffffff');
        this.statusBar.styleDefault();

        break;
    }
  }
}
