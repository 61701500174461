import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ArianeeService } from '../../providers/arianee-service/arianee.service';
import { mergeMap, take } from 'rxjs/operators';
import { Subscription } from 'rxjs';

interface SenderMessage{name:string;address:string, isAllowed:boolean, modifying:boolean}

@Component({
  selector: 'app-noti-option',
  templateUrl: './noti-option.component.html',
  styleUrls: ['./noti-option.component.scss']
})
export class NotiOptionComponent implements OnInit {
  @Input() certificateId: string;
  private subscriptions:Array<Subscription>=[];$
  public senders:SenderMessage[]=[];
  public loading=true;

  constructor (
    private arianeeService:ArianeeService,
    private modalCtrl: ModalController
  ) { }

  ngOnInit () {
    const messageSenderSub = this.arianeeService.methods
      .pipe(mergeMap(methods => methods.getCertificate(this.certificateId, undefined, { messageSenders: true, issuer: true })))
      .subscribe(certificateSummary => {
        this.loading = false;
        const issuerAddress = certificateSummary.issuer.identity.address;
        // @ts-ignore
        const issuerName = certificateSummary.issuer.identity.data.name;

        this.senders = [
          { name: issuerName, address: issuerAddress, isAllowed: certificateSummary.messageSenders[issuerAddress], modifying: false }
        ];
      });

    this.subscriptions.push(messageSenderSub);
  }

  onClickClose () {
    this.modalCtrl.dismiss();
  }

  onToggle (senderToModify:SenderMessage) {
    const findRef = this.senders.find(sender => sender.address === senderToModify.address);
    findRef.modifying = true;

    this.arianeeService.methods
      .pipe(mergeMap(methods => methods.setMessageAuthorizationFor(this.certificateId, senderToModify.address, !senderToModify.isAllowed)),
        take(1)
      ).subscribe(d => {
        findRef.modifying = false;
      }, (err) => {
        findRef.modifying = false;
        findRef.isAllowed = !findRef.isAllowed;
      });
  }

  ionViewDidLeave () {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
