import { Component, Input, OnInit } from '@angular/core';
import { IModalHeaderConfig } from '../components/modal-header/modal-header.component';
import { ModalController } from '@ionic/angular';
import Web3 from 'web3';
import {GAS_STATION} from "@arianee/gas-station";
import {getCurrencySymbol} from "../../../providers/wallet-connect-service/chain-utils";
import {TranslateService} from "@ngx-translate/core";
@Component({
  selector: 'app-wallet-transaction-modal',
  templateUrl: './wallet-transaction-modal.component.html',
  styleUrls: ['./wallet-transaction-modal.component.scss']
})
export class WalletTransactionModalComponent implements OnInit {
  @Input() logo:string;
  @Input() website:string;
  @Input() payload:any;
  @Input() gasPrice:GAS_STATION;
  @Input() method:string;
  headerConfig: IModalHeaderConfig = {
    logo: '',
    title: 'Sign this message',
    subtitle: {
      label: '',
      verified: false,
    },
    description: ''
  }

  public value: string;
  public estimatedFees:string;
  public total:string;
  public data:string;
  public symbol:string;

  constructor (
      public modalController: ModalController,
      public translateService: TranslateService
  ) { }

  ngOnInit () {

    this.headerConfig.title = this.translateService.instant('WalletConnect.signTransaction.title');
    this.headerConfig.logo = this.logo;
    this.headerConfig.subtitle.label = this.website;

    const transactionParams = this.payload.params[0];
    const web3 = new Web3();
    const gasToWei = web3.utils.toWei(web3.utils.toBN(Math.round(this.gasPrice.standard)), 'gwei') ;
    const estimatedFeesBN = web3.utils.toBN(transactionParams.gas).mul(gasToWei);
    const valueBn = web3.utils.toBN(transactionParams.value);
    this.value = web3.utils.fromWei(transactionParams.value, 'ether');
    this.estimatedFees = web3.utils.fromWei(estimatedFeesBN, 'ether');
    this.total = web3.utils.fromWei(estimatedFeesBN.add(valueBn), 'ether');

    this.data = web3.utils.hexToAscii(transactionParams.data);

  }
}
