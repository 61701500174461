import {Injectable} from '@angular/core';
import WalletConnect from '@walletconnect/client';
import {ArianeeService} from '../arianee-service/arianee.service';
import {ArianeeWallet} from '@arianee/arianeejs/dist/src/core/wallet';
import {ModalController} from '@ionic/angular';
import {of} from 'rxjs';
import {
  ConnectToWebsiteModalComponent
} from '../../components/wallet-connect-modals/connect-to-website-modal/connect-to-website-modal.component';
import {
  WalletConnectionLoaderComponent
} from '../../components/wallet-connect-modals/wallet-connection-loader/wallet-connection-loader.component';
import {
  WalletConnectedModalComponent
} from '../../components/wallet-connect-modals/wallet-connected-modal/wallet-connected-modal.component';
import {decodeWCUri} from './utils';
import {WalletConnectRequestHandlerService} from './wallet-connect-requestHandler.service';
import {HttpClient} from '@angular/common/http';
import {WalletConnectRequestPayload} from '../../models/walletConnect';
import {
  WalletSignMessageModalComponent
} from '../../components/wallet-connect-modals/wallet-sign-message-modal/wallet-sign-message-modal.component';
import {environment} from '../../../environments/environment';
import {
  WalletSwitchChainModalComponent
} from 'src/app/components/wallet-connect-modals/wallet-switch-chain-modal/wallet-switch-chain-modal.component';
import {
  WalletTransactionModalComponent
} from "../../components/wallet-connect-modals/wallet-transaction-modal/wallet-transaction-modal.component";
import {GasStationService} from "../gas-station-service/gas-station.service";
import {getCurrencySymbol, getProviderOfChain, SwitchEthereumChainFallbackRpc} from "./chain-utils";
import {
  WalletErrorModalComponent
} from '../../components/wallet-connect-modals/wallet-error-modal/wallet-error-modal.component';
import { mergeMap, take } from 'rxjs/operators';
import {
  ScanCompatibleModalComponent
} from '../../components/wallet-connect-modals/scan-compatible-modal/scan-compatible-modal.component';
import { LoaderService } from '@arianeeprivate/wallet-shared-components';

@Injectable({
  providedIn: 'root'
})
export class WalletConnectService {
    private connector;
    private wallet: ArianeeWallet;

    constructor (
    private arianeeService: ArianeeService,
    private modalCtrl:ModalController,
    private walletConnectRequestHandler : WalletConnectRequestHandlerService,
    private httpClient: HttpClient,
    private gasStation: GasStationService,
    private loaderService: LoaderService
    ) {
      this.arianeeService.$wallet.subscribe((wallet) => {
        this.wallet = wallet;
        this.checkPreviousSession();
      });
    }

    private checkPreviousSession = () => {
      const previousSession = localStorage.getItem('walletconnect');
      if (!previousSession) {
        return false;
      }
      this.handleSession(JSON.parse(previousSession));
    }

    private handleConnection = async (session: { uri: string, clientMeta?: any, [key: string]: any }) => {
      let newTopic = session.handshakeTopic;

      if (session.uri) {
        newTopic = decodeWCUri(session.uri).topic;
      }

      if (!session.clientMeta) {
        session.clientMeta = {
          description: 'Arianee .Wallet app',
          url: 'https://arianee.org',
          icons: [''],
          name: '.Wallet'
        };
      }

      try {
        this.connector = new WalletConnect(session);
        if (this.connector.handshakeTopic !== newTopic) {
          await this.connector.killSession();
          this.connector = new WalletConnect(session);
        }
      } catch (e) {
        console.error('wc connect error', e);
      }

      this.loaderService.dismiss();
    }

    public handleLink = async (link: string) => {
      this.arianeeService
        .$wallet
        .pipe(
          take(1),
          mergeMap(async()=>{
            await this.walletConnectRequestHandler.init();
            await this.handleConnection({ uri: link });
            this.initWatcher();
          })
        )
        .subscribe()

      return of(true);
    }

    private handleSession = async (session: any) => {
      await this.handleConnection(session);
      this.initWatcher();
      return of(true);
    }

    private initWatcher () {
      this.connector.on('session_request', async (error, payload) => {
        this.loaderService.dismiss();
        const params = payload.params[0];
        const peerInfo = params.peerMeta;
        const startingChainId = await this.getStartingChainId(params.chainId, params.fallbackRpc);
        this.displayApproveSessionModal(startingChainId, peerInfo);
      });

      this.connector.on('call_request', async (error, payload:WalletConnectRequestPayload) => {
        this.callRequestHandler(payload);
      });

      this.connector.on('wc_sessionRequest', (error, payload) => {
      });

      this.connector.on('connect', (error, payload) => {
      });

      this.connector.on('session_update', (error, payload) => {
        if (error) {
          throw error;
        }
      });

      this.connector.on('disconnect', (error, payload) => {
      });
    }

    /**
     * We don't want to switch to a chain the wallet is unable to support (no
     * known provider for the chain and no, or invalid, fallback rpc provided)
     * We therefore check if a provider can be found for the desired chain, in which case it can
     * can be used as the starting chain, or we default to the Ethereum mainnet chain otherwise.
     * @param desiredChainId the chain the dApp demand the wallet to be on
     * @param fallbackRpc an optional fallback rpc that some dApp may provide to facilitate switching to their chain
     * @returns the desired chain id if a provider was found, the ethereum mainnet chain id (1) otherwise
     */
    private async getStartingChainId (desiredChainId: number, fallbackRpc?: SwitchEthereumChainFallbackRpc): Promise<number> {
      const mainnetChainId = 1;
      if (!desiredChainId) return mainnetChainId;
      const providerForDesiredChain = await getProviderOfChain(desiredChainId, fallbackRpc);
      return providerForDesiredChain ? desiredChainId : mainnetChainId;
    }

    private displayApproveSessionModal = async (chainId, peerInfo) => {
      const verifiedinfos = await this.getVerifiedInfos(peerInfo);

      const modal = await this.modalCtrl.create({
        component: ConnectToWebsiteModalComponent,
        cssClass: 'modal-wallet-connect-style',
        backdropDismiss: false,
        componentProps: {
          logo: verifiedinfos.img || peerInfo.icons[0],
          website: new URL(peerInfo.url).hostname,
          isVerified: verifiedinfos.url !== undefined
        }
      });
      await modal.present();
      const { data } = await modal.onWillDismiss();
      if (data) {
        try {
          await this.walletConnectRequestHandler.trySwitchChain(chainId);
          this.approveSession(chainId);
          await this.displayTimedLoader('Connecting your wallet', 2000);
          this.displayConnectedModal(peerInfo);
        } catch {
          this.rejectSession();
        }
      } else {
        this.rejectSession();
      }
    }

    private displayConnectedModal = async (peerInfo) => {
      const modal = await this.modalCtrl.create({
        component: WalletConnectedModalComponent,
        cssClass: 'modal-wallet-connect-style',
        backdropDismiss: false,
        componentProps: {
          logo: peerInfo.icons[0],
          website: peerInfo.url
        }
      });
      modal.present();
    }

     displayCompatibleModal = async () => {
       const modal = await this.modalCtrl.create({
         component: ScanCompatibleModalComponent,
         cssClass: 'modal-wallet-connect-style',
         backdropDismiss: true
       });
       modal.present();
     }

    private displayTimedLoader = async (message: string, loaderTime?: number): Promise<Function | void> => {
      return new Promise(async (resolve, reject) => {
        const modal = await this.modalCtrl.create({
          component: WalletConnectionLoaderComponent,
          cssClass: 'modal-loading',
          backdropDismiss: false,
          componentProps: {
            message: message
          }
        });
        await modal.present();
        if(loaderTime){
          setTimeout(() => {
            modal.dismiss();
            resolve();
          }, loaderTime);
        }
        resolve(()=>modal.dismiss());
      });
    }

  private displaySignMessageModal = async (peerInfo, payload:WalletConnectRequestPayload) => {
    let message;
    switch (payload.method) {
      case 'eth_sign':
        message = payload.params[1];
        break;
      case 'personal_sign':
        message = payload.params[0];
        break;
      case 'eth_signTypedData':
        if(typeof payload.params[1] === 'string'){
          message = payload.params[1];
        } else {
          message = JSON.stringify(payload.params[1]);
        }
        break;
    }

    const modal = await this.modalCtrl.create({
      component: WalletSignMessageModalComponent,
      cssClass: 'modal-wallet-connect-style',
      backdropDismiss: false,
      componentProps: {
        logo: peerInfo.icons[0],
        website: peerInfo.url,
        message
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    return data;
  }

  private displayTransactionModal = async (peerInfo, payload:WalletConnectRequestPayload, chainId:string, method:string) => {
    const gasPrice = await this.gasStation.fetchGasStation(chainId);

    const modal = await this.modalCtrl.create({
      component: WalletTransactionModalComponent,
      cssClass: 'modal-wallet-connect-style',
      backdropDismiss: false,
      componentProps: {
        logo: peerInfo.icons[0],
        website: peerInfo.url,
        symbol: getCurrencySymbol(+chainId),
        payload,
        gasPrice,
        method
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if(data && method === "eth_sendTransaction"){
      const loaderDismiss = await this.displayTimedLoader('Your transaction is minting')
      return {data, gasPrice:gasPrice.standard, loaderDismiss:loaderDismiss};
    }
    return {data, gasPrice:gasPrice.standard};
  }

  private displaySwitchChainModal = async (chainId: number, error: boolean = false) => {
    const verifiedinfos = await this.getVerifiedInfos(this.connector.peerMeta);

    const modal = await this.modalCtrl.create({
      component: WalletSwitchChainModalComponent,
      cssClass: 'modal-wallet-connect-style',
      backdropDismiss: false,
      componentProps: {
        logo: this.connector.peerMeta.icons[0],
        website: this.connector.peerMeta.url,
        verified: verifiedinfos.url !== undefined,
        chainId,
        error
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    return data;
  }

  private displayErrorModal = async () => {
      const modal = await this.modalCtrl.create({
        component: WalletErrorModalComponent,
        cssClass: 'modal-wallet-connect-style',
        backdropDismiss: false,
        componentProps: {
          status:'ERROR'
        }
      });
      modal.present();
  }

  private callRequestModalHandler = async (payload):Promise<{accepted:boolean, gasPrice?:number, loaderDismiss?:Function}>=>{
    if (payload.method === 'wallet_switchEthereumChain') {
      const chainId = parseInt(payload.params[0].chainId, 16);
      // Prevent many popup for the same switch chain request
      if (this.connector.session.chainId === chainId) return;
       const accepted = await this.displaySwitchChainModal(chainId);

       return {accepted};
    }
    else if(payload.method === 'eth_signTransaction' || payload.method === 'eth_sendTransaction'){
      const {data, gasPrice:gasPriceModal, loaderDismiss:loaderDismissModal} = await this.displayTransactionModal(this.connector.peerMeta, payload, ""+this.walletConnectRequestHandler.chainId, payload.method);
      if(loaderDismissModal){
        return {accepted:data, gasPrice:gasPriceModal, loaderDismiss:loaderDismissModal};
      }
      return {accepted:data, gasPrice:gasPriceModal}

    }
    else{
      const accepted = await this.displaySignMessageModal(this.connector.peerMeta, payload);
      return {accepted};
    }
  }

  private callRequestHandler = async (payload:WalletConnectRequestPayload) => {
    const {accepted, gasPrice, loaderDismiss} = await this.callRequestModalHandler(payload);

    if (accepted) {
      try {

        if(payload.method === 'eth_signTransaction' || payload.method === 'eth_sendTransaction'){
          payload.params[0].gasPrice = gasPrice;
        }

        if(payload.method === 'wallet_switchEthereumChain'){
          this.updateSession(payload.params[0].chainId);
        }

        const result = await this.walletConnectRequestHandler.handleRequest(payload);
        if(loaderDismiss){
          loaderDismiss();
        }
        this.connector.approveRequest({
          id: payload.id,
          result
        });
      } catch (e) {
        if(loaderDismiss){
          loaderDismiss();
        }
        this.displayErrorModal();
        this.connector.rejectRequest({
          id: payload.id,
          error: 'An error occurred'
        });
      }
    } else {
      this.connector.rejectRequest({
        id: payload.id,
        error: 'User rejected request'
      });
    }
  }

  private approveSession = (chainId:number) => {
    this.connector.approveSession({
      chainId: chainId,
      accounts: [ // required
        this.wallet.address
      ]
    });
  }

  private updateSession = (chainId:number) => {
    this.connector.updateSession({
      chainId,
      accounts: [ // required
        this.wallet.address
      ]
    });
  }

  private rejectSession = () => {
    this.connector.rejectSession();
    this.connector.killSession('User reject the sessions');
  }

  public getVerifiedInfos = async (peerInfos: {url:string}): Promise<{url?:string, img?:string}> => {
    const verifiedWebsiteList:Array<{url:string, img?:string}> = await this.httpClient.get<any>(
      environment.walletConnectVerified, {
        responseType: 'json'
      }).toPromise().catch(e => []);

    const websiteHost = new URL(peerInfos.url).host;
    const verifiedWebsiteObject = verifiedWebsiteList.find(d => {
      return d.url === websiteHost;
    });

    return verifiedWebsiteObject || {};
  }
}
