import { get } from 'lodash';
import fetch from 'node-fetch';
import { chainsInfos } from './chains-infos';
import Axios from 'axios';

export interface SwitchEthereumChainCallRequest {
  chainId: string;
  fallbackRpc: SwitchEthereumChainFallbackRpc
}

export interface SwitchEthereumChainFallbackRpc {
  chainId: string;
  chainName?: string;
  rpcUrl: string;
  nativeCurrency?: {
    name: string;
    symbol: string;
    decimals: number;
  },
  blockExplorerUrl?: string;
}

const KNOWN_PROVIDERS : { [key: number] : string } = {
  1: 'https://ethereum.arianee.net',
  137: 'https://poly.arianee.net',
  77: 'https://sokol.arianee.net',
  99: 'https://poa.arianee.net'
};

/**
 * Get the name of a chain whose id is chainId
 * @param chainId id of the chain to get the name of
 * @returns name of the chain whose id is chainId, 'unknown chain ({chainId})' if name not found
 */
export const getChainName = (chainId: number) : string => {
  return get(chainsInfos.filter(chain => chain.chainId === chainId)[0], 'name', `unknown chain (${chainId.toString()})`);
};

export const getCurrencySymbol = (chainId:number): string =>{
  return get(chainsInfos.filter(chain => chain.chainId === chainId)[0], 'nativeCurrency.symbol', 'ETH');
}

/**
 * Get the provider of a chain whose id is chainId, if no provider exists for that chain, returns null
 * @param chainId id of the chain to get a provider of
 * @returns a provider if one exists, null otherwise
 */
export const getProviderOfChain = async (chainId: number, fallbackRpc?: SwitchEthereumChainFallbackRpc) : Promise<string | null> => {
  const knownProvider = get(KNOWN_PROVIDERS, chainId, null);
  if (knownProvider) return knownProvider;
  if (!fallbackRpc) return null;

  if (await isAProviderOf(chainId, fallbackRpc)) return fallbackRpc.rpcUrl;
  return null;
};

/**
 * Check if a provider is a provider of the chain with id chainId
 * @param chainId id of the chain the provider must be checked against
 * @param fallbackRpc the provider to check
 * @returns true if the provider is a provider of the chain whose id is chainId, false otherwise
 */
export const isAProviderOf = async (chainId: number, fallbackRpc: SwitchEthereumChainFallbackRpc) : Promise<boolean> => {
  if (!fallbackRpc || !fallbackRpc.rpcUrl) return false;
  const rpcUrl = fallbackRpc.rpcUrl;

  const response = await Axios({
    method: 'post',
    url: rpcUrl,
    data: JSON.stringify({ jsonrpc: '2.0', method: 'eth_chainId', params: [], id: 1 }),
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  })

  const json: any = await response.data;
  const providerChainId = parseInt(json.result, 16);

  return providerChainId === chainId;
};
