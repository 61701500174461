import {Component, Input, OnInit} from '@angular/core';
import { IModalHeaderConfig } from '../components/modal-header/modal-header.component';
import { ModalController } from '@ionic/angular';
import { IModalList } from '../components/modal-list-with-icon/modal-list-with-icon.component';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-connect-to-website-modal',
  templateUrl: './connect-to-website-modal.component.html',
  styleUrls: ['./connect-to-website-modal.component.scss']
})
export class ConnectToWebsiteModalComponent implements OnInit {

  @Input() logo:string
  @Input() website:string;
  @Input() isVerified:boolean;
  headerConfig: IModalHeaderConfig = {
    logo: '',
    title: 'Connect to web site',
    subtitle: {
      label: '',
      verified: false
    }
  }

  dataList: IModalList[] = [
    {
      icon: './assets/imgs/court-icon.svg',
      label: 'Let it see your wallet balance and activity'
    },
    {
      icon: './assets/imgs/check-icon.svg',
      label: 'Let it send you requests for transactions'
    },
    {
      icon: './assets/imgs/close-icon.svg',
      label: 'It cannot move funds without your permission'
    }
  ];

  constructor (
      public modalController: ModalController,
      public translate: TranslateService
  ) {

  }

  ngOnInit() {
    this.headerConfig.title= this.translate.instant('WalletConnect.connect.title');
    this.dataList[0].label= this.translate.instant('WalletConnect.connect.list.0');
    this.dataList[1].label= this.translate.instant('WalletConnect.connect.list.1');
    this.dataList[2].label= this.translate.instant('WalletConnect.connect.list.2');

    this.headerConfig.logo = this.logo;
    this.headerConfig.subtitle.label = this.website;
    this.headerConfig.subtitle.verified = this.isVerified;
  }

}
